<template>
  <div>
    <div v-if="$mq === 'sm'"
         class="page-endpoint--mobile">
      <Header></Header>
      <div class="container main-container">
        <b-tabs>
          <b-tab title="Requests">
            <SideNav></SideNav>
          </b-tab>
          <b-tab active
                 title="Details">
            <div class="px-2">
              <!--Main-->
              <div class="main">
                <div class="request-details-plot">
                  <div class="db-block w-100 float-right mt-3">
                    <div
                      class="row">
                      <div class="col-xl-3 col-lg-12 col-sm-12 col-12">
                        <div class="card bg-light b-radius-8 shadow-1 dashcard">
                          <div class="card-body p-0 py-2">
                            <div class="row align-items-center h-100">
                              <div class="dashcard-dark-side">
                                <h1 v-if="endpointData.requestCount">
                                  {{ endpointData.requestCount }}
                                </h1>
                                <h1 v-else>
                                  0
                                </h1>
                                <span class="text-white text-center">
                          Total Count
                        </span>
                              </div>
                              <div class="dashcard-light-side p-3">
                                <div class="mb-xl-3">
                                  <span class="w-100 clearfix">Created:</span>
                                  <span>{{ endpointData.createDate | localDate }}</span>
                                </div>
                                <div>
                                  <span class="w-100 clearfix">Last Request:</span>
                                  <span>{{ endpointData.lastRequestDate | localDate }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-12 col-sm-12 col-12 mt-xl-0 px-xl-0 mt-lg-3 mt-md-3 mt-3">
                        <div class="card bg-light text-white b-radius-8 shadow-1 dashcard">
                          <div class="card-body p-0 pt-2">
                            <ChartRequestPerSecond></ChartRequestPerSecond>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-12 col-sm-12 col-12 mt-xl-0 mt-lg-3 mt-md-3 mt-3">
                        <div class="card bg-light text-white b-radius-8 shadow-1 dashcard">
                          <div class="card-body p-0 pt-2">
                            <HeartRate></HeartRate>
                            <span class="heart-rate-chart-title w-100 db-block float-right text-center">
                              Heart Beat
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-12 col-sm-12 col-12 mt-xl-0 mt-lg-3 mt-md-3 mt-3">
                        <div class="card bg-light b-radius-8 shadow-1 dashcard dashcard-sample">
                          <div class="card-body p-3">
                          <p class="mb-2">HTTP setting</p>
                          <ol class="pl-4 text-gray">
                            <li>{{ `URL: hook.ubeac.io/${$route.query.id}` }}</li>
                            <li>Protocols: HTTP, HTTPS</li>
                          </ol>
                          <p class="mb-2">MQTT setting</p>
                          <ol class="pl-4 text-gray">
                            <li>Host: hook.ubeac.io:1885</li>
                            <li>Protocol: TCP</li>
                            <li>{{ `Topic: ${$route.query.id}` }}</li>
                          </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="detailsData && Object.keys(detailsData).length !== 0"
                    class="db-block w-100 float-right mt-4 p-0">
                    <div
                      class="col-xl-12 col-lg-12 col-sm-12 col-12 mt-lg-3 mt-xl-0 mt-md-3 mt-3 px-0 shadow-1">
                      <div class="card  bg-light request-details-head-box">
                        <div class="card-body p-0 py-2 pb-3">
                          <h4 class="text-center">
                            <div
                              :class="returnRequestStatusClass(detailsData.method)"
                              class="badge badge-success float-left">
                      <span>
                        {{ detailsData.method }}
                      </span>
                            </div>
                          </h4>
                          <b-button
                            v-b-tooltip.hover
                            variant="outline-primary"
                            size="sm"
                            class="btn-sm btn-info mr-0 float-right request-details-plot--toolbox"
                            title="Export"
                            @click="downloadRequest(detailsData)">
                            <icon name="download"></icon>
                          </b-button>
                          <ul>
                            <li>{{ detailsData.date | localDate }}</li>
                            <li><b>Trace Id:</b> {{ detailsData.traceId }}</li>
                            <li><b>IP:</b> {{ detailsData.clientData.ip }}</li>
                            <li><b>Port:</b> {{ detailsData.clientData.port }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="db-block w-100 float-right mt-3">
                    <div
                      v-if="detailsData && Object.keys(detailsData).length !== 0"
                      class="row">
                      <div class="col-12 mt-0">
                        <div
                          v-if="detailsData && Object.keys(detailsData).length !== 0"
                          class="card shadow-1">

                          <b-tabs
                            class="tabs-container"
                            pills
                            card>
                            <b-tab title="Headers">
                              <div
                                v-if="detailsData && detailsData.headers"
                                class="request-body-toolbox">
                                <b-button
                                  v-b-tooltip.hover
                                  variant="outline-primary"
                                  size="sm"
                                  class="btn-sm btn-info ml-1 float-right clipboard-content-btn"
                                  data-clipboard="requestHeadersInput"
                                  title="Copy Headers">
                                  <!--contentCopyIcon-->
                                  <icon :name="contentCopyIcon"></icon>
                                  <input
                                    id="requestHeadersInput"
                                    :value="JSON.stringify(detailsData.headers)"
                                    type="hidden"
                                    style="display: none;">
                                </b-button>
                              </div>
                              <div class="tab-contents">
                                <table
                                  class="table table-striped table-sm ">
                                  <tbody>
                                    <tr
                                      v-for="(value, key) in detailsData.headers"
                                      :key="key">
                                      <td class="w-25 text-capital">{{ key }}</td>
                                      <td>{{ value.join(', ') }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-tab>
                            <b-tab
                              title="Body"
                              active>
                              <div
                                v-if="detailsData && detailsData.content"
                                class="request-body-toolbox">
                                <b-button
                                  v-b-tooltip.hover
                                  variant="outline-primary"
                                  size="sm"
                                  class="btn-sm btn-info ml-3 float-right clipboard-content-btn"
                                  data-clipboard="requestContentInput"
                                  title="Copy Content">
                                  <!--contentCopyIcon-->
                                  <icon :name="contentCopyIcon"></icon>
                                  <input
                                    id="requestContentInput"
                                    :value="detailsData.content"
                                    type="hidden"
                                    style="display: none;">
                                </b-button>
                                <b-dropdown
                                  id="down-sm"
                                  :text="contentViewFormat"
                                  class="float-right"
                                  right
                                  variant="outline-primary"
                                  size="sm">
                                  <b-dropdown-item @click="changeContentViewFormat('raw')">Raw</b-dropdown-item>
                                  <b-dropdown-item @click="changeContentViewFormat('json')">Json</b-dropdown-item>
                                  <b-dropdown-item @click="changeContentViewFormat('yaml')">Yaml</b-dropdown-item>
                                  <b-dropdown-item @click="changeContentViewFormat('xml')">XML</b-dropdown-item>
                                  <b-dropdown-item @click="changeContentViewFormat('html')">HTML</b-dropdown-item>
                                </b-dropdown>
                              </div>
                              <div
                                v-if="detailsData && detailsData.content"
                                class="tab-contents">
                                <Highlight
                                  v-if="['html', 'yaml', 'xml'].indexOf(contentViewFormat) > -1"
                                  :current-language="contentViewFormat"
                                  :code-source="detailsData.content">
                                </Highlight>
                                <textarea
                                  v-if="contentViewFormat === 'raw'"
                                  v-model="detailsData.content"
                                  class="form-control request-body--raw"
                                  readonly>
                        </textarea>
                                <tree-view
                                  v-if="contentViewFormat === 'json'"
                                  :data="getTreeViewJsonData(detailsData.content)"
                                  :options="{maxDepth: 2}">
                                </tree-view>
                              </div>
                              <p
                                v-else
                                class="text-center text-muted">
                                THERE IS NO CONTENT WITH REQUEST :(
                              </p>
                            </b-tab>
                            <b-tab title="Client Info">
                              <div
                                v-if="detailsData && detailsData.headers"
                                class="request-body-toolbox">
                                <b-button
                                  v-b-tooltip.hover
                                  variant="outline-primary"
                                  size="sm"
                                  class="btn-sm btn-info ml-1 float-right clipboard-content-btn"
                                  data-clipboard="requestClientInfoInput"
                                  title="Copy Client Info">
                                  <icon :name="contentCopyIcon"></icon>
                                  <input
                                    id="requestClientInfoInput"
                                    :value="JSON.stringify(detailsData.clientData)"
                                    type="hidden"
                                    style="display: none;">
                                </b-button>
                              </div>
                              <div class="tab-contents">
                                <table
                                  class="table table-striped table-sm ">
                                  <tbody>
                                    <tr
                                      v-for="(value, key) in detailsData.clientData"
                                      :key="key">
                                      <td class="w-25 text-capital">
                                        <template v-if="key === 'ip'">
                                  <span class="text-uppercase">
                                    {{ key }}
                                  </span>
                                        </template>
                                        <template v-else>
                                  <span>
                                    {{ key }}
                                  </span>
                                        </template>
                                      </td>
                                      <td>
                                        <template
                                          v-if="value && value.join">
                                          {{ value.join(', ') }}
                                        </template>
                                        <template
                                          else>
                                          {{ value }}
                                        </template>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class=" w-100">
                      <NoRequest></NoRequest>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div v-else
         class="page-endpoint">
      <Header></Header>
      <div class="sidenav">
        <SideNav></SideNav>
      </div>
      <!--Main-->
      <div class="main">
        <div class="request-details-plot">
          <div class="db-block w-100 float-right mt-3">
            <div
              class="row">
              <div class="col-xl-3 col-lg-12 col-sm-12 col-12">
                <div class="card bg-light b-radius-8 shadow-1 dashcard">
                  <div class="card-body p-0 py-2">
                    <div class="row align-items-center h-100">
                      <div class="dashcard-dark-side">
                        <h1 v-if="endpointData.requestCount">
                          {{ endpointData.requestCount }}
                        </h1>
                        <h1 v-else>
                          0
                        </h1>
                        <span class="text-white text-center">
                        Total Count
                      </span>
                      </div>
                      <div class="dashcard-light-side p-3">
                        <div class="mb-xl-3">
                          <span class="w-100 clearfix">Created:</span>
                          <span>{{ endpointData.createDate | localDate }}</span>
                        </div>
                        <div>
                          <span class="w-100 clearfix">Last Request:</span>
                          <span>{{ endpointData.lastRequestDate | localDate }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-12 col-sm-12 col-12 mt-xl-0 px-xl-0 mt-lg-3 mt-md-3 mt-3">
                <div class="card bg-light text-white b-radius-8 shadow-1 dashcard">
                  <div class="card-body p-0 pt-2">
                    <ChartRequestPerSecond></ChartRequestPerSecond>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-12 col-sm-12 col-12 mt-xl-0 pr-xl-0 mt-lg-3 mt-md-3 mt-3">
                <div class="card bg-light text-white b-radius-8 shadow-1 dashcard">
                  <div class="card-body p-0 pt-2">
                    <HeartRate></HeartRate>
                    <span class="heart-rate-chart-title w-100 db-block float-right text-center">
                    Heart Beat
                  </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-12 col-sm-12 col-12 mt-xl-0 mt-lg-3 mt-md-3 mt-3">
                <div class="card bg-light b-radius-8 shadow-1 dashcard dashcard-sample">
                  <div class="card-body p-3">
                   <p class="mb-2">HTTP setting</p>
                      <ol class="pl-4 text-gray">
                        <li>{{ `URL: hook.ubeac.io/${$route.query.id}` }}</li>
                        <li>Protocols: HTTP, HTTPS</li>
                      </ol>
                      <p class="mb-2">MQTT setting</p>
                      <ol class="pl-4 text-gray">
                        <li>Host: hook.ubeac.io:1885</li>
                        <li>Protocol: TCP</li>
                        <li>{{ `Topic: ${$route.query.id}` }}</li>
                      </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="detailsData && Object.keys(detailsData).length !== 0"
            class="db-block w-100 float-right mt-4 p-0">
            <div
              class="col-xl-12 col-lg-12 col-sm-12 col-12 mt-lg-3 mt-xl-0 mt-md-3 mt-3 px-0 shadow-1">
              <div class="card  bg-light request-details-head-box">
                <div class="card-body p-0 py-2 pb-3">
                  <h4 class="text-center">
                    <div
                      :class="returnRequestStatusClass(detailsData.method)"
                      class="badge badge-success float-left">
                    <span>
                      {{ detailsData.method }}
                    </span>
                    </div>
                  </h4>
                  <b-button
                    v-b-tooltip.hover
                    variant="outline-primary"
                    size="sm"
                    class="btn-sm btn-info mr-0 float-right request-details-plot--toolbox"
                    title="Export"
                    @click="downloadRequest(detailsData)">
                    <icon name="download"></icon>
                  </b-button>
                  <ul>
                    <li>{{ detailsData.date | localDate }}</li>
                    <li><b>Trace Id:</b> {{ detailsData.traceId }}</li>
                    <li><b>IP:</b> {{ detailsData.clientData.ip }}</li>
                    <li><b>Port:</b> {{ detailsData.clientData.port }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="db-block w-100 float-right mt-3">
            <div
              v-if="detailsData && Object.keys(detailsData).length !== 0"
              class="row">
              <div class="col-12 mt-0">
                <div
                  v-if="detailsData && Object.keys(detailsData).length !== 0"
                  class="card shadow-1">

                  <b-tabs
                    class="tabs-container"
                    pills
                    card>
                    <b-tab title="Headers">
                      <div
                        v-if="detailsData && detailsData.headers"
                        class="request-body-toolbox">
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          size="sm"
                          class="btn-sm btn-info ml-1 float-right clipboard-content-btn"
                          data-clipboard="requestHeadersInput"
                          title="Copy Headers">
                          <!--contentCopyIcon-->
                          <icon :name="contentCopyIcon"></icon>
                          <input
                            id="requestHeadersInput"
                            :value="JSON.stringify(detailsData.headers)"
                            type="hidden"
                            style="display: none;">
                        </b-button>
                      </div>
                      <div class="tab-contents">
                        <table
                          class="table table-striped table-sm ">
                          <tbody>
                            <tr
                              v-for="(value, key) in detailsData.headers"
                              :key="key">
                              <td class="w-25 text-capital">{{ key }}</td>
                              <td>{{ value.join(', ') }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                    <b-tab
                      title="Body"
                      active>
                      <div
                        v-if="detailsData && detailsData.content"
                        class="request-body-toolbox">
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          size="sm"
                          class="btn-sm btn-info ml-3 float-right clipboard-content-btn"
                          data-clipboard="requestContentInput"
                          title="Copy Content">
                          <!--contentCopyIcon-->
                          <icon :name="contentCopyIcon"></icon>
                          <input
                            id="requestContentInput"
                            :value="detailsData.content"
                            type="hidden"
                            style="display: none;">
                        </b-button>
                        <b-dropdown
                          id="down-sm"
                          :text="contentViewFormat"
                          class="float-right"
                          right
                          variant="outline-primary"
                          size="sm">
                          <b-dropdown-item @click="changeContentViewFormat('raw')">Raw</b-dropdown-item>
                          <b-dropdown-item @click="changeContentViewFormat('json')">Json</b-dropdown-item>
                          <b-dropdown-item @click="changeContentViewFormat('yaml')">Yaml</b-dropdown-item>
                          <b-dropdown-item @click="changeContentViewFormat('xml')">XML</b-dropdown-item>
                          <b-dropdown-item @click="changeContentViewFormat('html')">HTML</b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <div
                        v-if="detailsData && detailsData.content"
                        class="tab-contents">
                        <Highlight
                          v-if="['html', 'yaml', 'xml'].indexOf(contentViewFormat) > -1"
                          :current-language="contentViewFormat"
                          :code-source="detailsData.content">
                        </Highlight>
                        <textarea
                          v-if="contentViewFormat === 'raw'"
                          v-model="detailsData.content"
                          class="form-control request-body--raw"
                          readonly>
                      </textarea>
                        <tree-view
                          v-if="contentViewFormat === 'json'"
                          :data="getTreeViewJsonData(detailsData.content)"
                          :options="{maxDepth: 2}">
                        </tree-view>
                      </div>
                      <p
                        v-else
                        class="text-center text-muted">
                        THERE IS NO CONTENT WITH REQUEST :(
                      </p>
                    </b-tab>
                    <b-tab title="Client Info">
                      <div
                        v-if="detailsData && detailsData.headers"
                        class="request-body-toolbox">
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          size="sm"
                          class="btn-sm btn-info ml-1 float-right clipboard-content-btn"
                          data-clipboard="requestClientInfoInput"
                          title="Copy Client Info">
                          <icon :name="contentCopyIcon"></icon>
                          <input
                            id="requestClientInfoInput"
                            :value="JSON.stringify(detailsData.clientData)"
                            type="hidden"
                            style="display: none;">
                        </b-button>
                      </div>
                      <div class="tab-contents">
                        <table
                          class="table table-striped table-sm ">
                          <tbody>
                            <tr
                              v-for="(value, key) in detailsData.clientData"
                              :key="key">
                              <td class="w-25 text-capital">
                                <template v-if="key === 'ip'">
                                <span class="text-uppercase">
                                  {{ key }}
                                </span>
                                </template>
                                <template v-else>
                                <span>
                                  {{ key }}
                                </span>
                                </template>
                              </td>
                              <td>
                                <template
                                  v-if="value && value.join">
                                  {{ value.join(', ') }}
                                </template>
                                <template
                                  else>
                                  {{ value }}
                                </template>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div
              v-else
              class=" w-100">
              <NoRequest></NoRequest>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import ClipboardJS from 'clipboard'
  import HeartRate from '../components/HeartRate'
  import Highlight from '../components/Highlight'
  import Config from '@/config/config.js'
  import ChartRequestPerSecond from '../components/ChartRequestPerSecond'
  import NoRequest from '../components/NoRequest'
  import Header from '../components/Header'
  import SideNav from '../components/SideNav'

  export default {
    name: 'Home',
    components: {HeartRate, Highlight, ChartRequestPerSecond, NoRequest, Header, SideNav},
    data () {
      return {
        contentViewFormat: 'xml',
        copyIcon: 'copy',
        contentCopyIcon: 'copy',
        showHappyClipboardTimeout: 2000,
        globalConfig: Config,
        chartUpdateInterval: null
      }
    },
    computed: {
      ...mapState('socket', ['overallData', 'endpointData', 'postedData', 'socket']),
      endpointUrl () {
        return Config.Urls.EndPointBaseUrl + this.$route.query.id
      },
      detailsData () {
        return this.$store.state.socket.endpointDetails
      }
    },
    watch: {
      detailsData () {
        this.setContentViewFormatBasedOfHeaderData()
      }
    },
    destroyed () {
      clearInterval(window.chartUpdateInterval)
    },
    mounted () {
      this.initClipBoard()
    },
    created () {
      let id = this.$route.query.id
      this.$store.dispatch('socket/initializeSocket').then(() => {
        this.$store.dispatch('socket/joinGroupSocket', id).then(() =>
          console.log('this.$store.state.socket.status')
        )
      })
      this.$store.dispatch('socket/getOverallData')
      this.$store.dispatch('socket/getEndpointData', id)
    },
    methods: {
      initClipBoard () {
        // Handle clipboard functionality
        /* eslint-disable */
        this.clipboardContent = new ClipboardJS('.clipboard-content-btn', {
          text: function (trigger) {
            return document.getElementById(trigger.dataset.clipboard).value
          }
        })
        this.clipboardContent.on('success', () => {
          console.log('clipboard-content-btn copy done')
          this.contentCopyIcon = 'check'
          setTimeout(() => {
            this.contentCopyIcon = 'copy'
          }, this.showHappyClipboardTimeout)
        })
        /* eslint-enable */
      },
      downloadRequest (content) {
        const data = 'text/jsoncharset=utf-8,' + encodeURIComponent(JSON.stringify(content))
        const a = document.createElement('a')
        a.href = 'data:' + data
        a.download = content.traceId + '.json'
        a.innerHTML = 'download JSON'
        a.click()
      },
      setContentViewFormatBasedOfHeaderData () {
        let headerContentTYpe = this.detailsData.headers['content-Type'] && this.detailsData.headers['content-Type'][0]
        if (headerContentTYpe) {
          // TODO: Need strict check
          if (headerContentTYpe.indexOf('json') > -1) {
            this.contentViewFormat = 'json'
          }
          if (headerContentTYpe.indexOf('text') > -1) {
            this.contentViewFormat = 'raw'
          }
          if (headerContentTYpe.indexOf('yaml') > -1) {
            this.contentViewFormat = 'yaml'
          }
          if (headerContentTYpe.indexOf('xml') > -1) {
            this.contentViewFormat = 'xml'
          }
          if (headerContentTYpe.indexOf('html') > -1) {
            this.contentViewFormat = 'html'
          }
        }
      },
      changeContentViewFormat (payload) {
        this.contentViewFormat = payload
      },
      returnRequestStatusClass (requestMethod) {
        let output = null
        switch (requestMethod.toLowerCase()) {
          case 'post':
            output = 'status-post'
            break
          case 'get':
            output = 'status-get '
            break
          case 'put':
            output = 'status-put'
            break
          case 'delete':
            output = 'status-delete'
            break
          default:
            output = ''
        }
        return output
      },
      getTreeViewJsonData (content) {
        try {
          return JSON.parse(content)
        } catch (err) {
          return {}
        }
      }
    }
  }
</script>
