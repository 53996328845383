<template>
  <div class="request-list-box">
    <b-modal
      id="myModal"
      centered
      cancel-variant="success"
      ok-variant="danger"
      hide-header
      ok-title="Clear"
      @ok="clearPostedData">
      <h4 class="db-block w-100 float-left">
        Are you trying clear list?
      </h4>
      <br>
      <br>
      <span class="text-danger w-100 mt-5 db-block">
        this action cannot be undone!
      </span>
    </b-modal>
    <div class="sidenav--toolbox db-block w-100">
        <span class="float-left ml-3 sidenav--title">
          Live Requests
          <span class="badge">
            {{ postedDataTotalCount }}
            <!--<small>{{ postedData.length }}</small>-->
          </span>
        </span>
      <button
        v-b-tooltip.hover
        title="Export All"
        class="btn btn-sm btn-outline-link btn-link float-right mt-1 mx-0 px-0"
        @click="exportAll">
        <icon name="download"></icon>
      </button>
      <button
        v-b-tooltip.hover
        v-b-modal="'myModal'"
        title="Clear List"
        class="btn btn-sm btn-outline-link btn-link float-right mt-1 mx-0 px-0">
        <icon name="trash-alt"></icon>
      </button>
      <button
        v-b-tooltip.hover
        title="Scroll to selected request"
        class="btn btn-sm btn-outline-link btn-link float-right mt-1 mx-0 px-0"
        @click="scrollToCurrentSelected">
        <icon name="bullseye"></icon>
      </button>
    </div>
    <div class="sidenav-content">
      <ul class="list-group request-list list-group-flush">
        <template v-if="postedData.length > 0">
          <li
            v-for="request in postedData"
            :class="[{'list-group-item-active': detailsData && request.traceId === detailsData.traceId}]"
            :key="request.traceId"
            class="list-group-item request-list--item"
            @click="showDetails(request.traceId)">
            <div
              :class="returnRequestStatusClass(request.method)"
              class="request-list--item--shape">
            </div>
            <div
              :class="returnRequestStatusClass(request.method)"
              class="badge badge-success">
              {{ request.method }}
            </div>
            <span class="text-muted ml-2 text-small">
                  {{ request.date | localDate }}
                </span>
            <p class="text-single-line mt-2 pb-1 mb-0 text-small">
              {{ request.clientData.ip }}
            </p>
            <p class="text-single-line text-muted mb-0 text-small">
              {{ request.traceId }}
            </p>
            <button
              v-b-tooltip.hover
              type="button"
              title="Remove"
              class="btn btn-sm btn-outline-danger btn-close"
              @click="deleteItem(request.traceId)">
              <icon name="times"></icon>
            </button>
          </li>
        </template>
        <template v-else>
          <div
            class="request-list--no-result">
            <img
              src="../../public/img/desert.svg"
              alt="">
            <br>
            <p class="pt-0 mt-0 h6 text-gray-300">
              <span>Waiting for requests</span>
            </p>
          </div>
          <li
            v-for="request in postedDataMock"
            class="list-group-item request-list--mock-item">
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import ClipboardJS from 'clipboard'
  import HeartRate from '../components/HeartRate'
  import Highlight from '../components/Highlight'
  import Config from '@/config/config.js'
  import ChartRequestPerSecond from '../components/ChartRequestPerSecond'
  import NoRequest from '../components/NoRequest'
  import Header from '../components/Header'
  import SideNav from '../components/SideNav'

  export default {
    name: 'SideNav',
    components: {},
    data () {
      const postedDataMock = this._.range(9)
      return {
        detailsData: {},
        globalConfig: Config,
        chartUpdateInterval: null,
        postedDataMock: postedDataMock
      }
    },
    computed: {
      ...mapState('socket', ['overallData', 'endpointData', 'postedData', 'socket', 'postedDataTotalCount']),
      endpointUrl () {
        return Config.Urls.EndPointBaseUrl + this.$route.query.id
      }
    },
    destroyed () {
      clearInterval(window.chartUpdateInterval)
    },
    methods: {
      exportAll () {
        const data = 'text/jsoncharset=utf-8,' + encodeURIComponent(JSON.stringify(this.postedData))
        const a = document.createElement('a')
        a.href = 'data:' + data
        a.download = this.$route.query.id + '.json'
        a.innerHTML = 'download JSON'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      },
      scrollToCurrentSelected () {
        const selectedElement = document.getElementsByClassName('list-group-item-active')[0]
        if (selectedElement) {
          selectedElement.scrollIntoView()
        }
      },
      returnRequestStatusClass (requestMethod) {
        let output = null
        switch (requestMethod.toLowerCase()) {
          case 'post':
            output = 'status-post'
            break
          case 'get':
            output = 'status-get '
            break
          case 'put':
            output = 'status-put'
            break
          case 'delete':
            output = 'status-delete'
            break
          default:
            output = ''
        }
        return output
      },
      showDetails (traceId) {
        this.detailsData = this.$store.getters['socket/getDetails'](traceId)
        this.$store.dispatch('socket/setEndPointDetails', this.detailsData)
        // // TODO: fix me
        // this.setContentViewFormatBasedOfHeaderData()
        // this.$forceUpdate()
      },
      deleteItem (traceId) {
        this.detailsData = this.$store.dispatch('socket/deleteItem', traceId)
      },
      clearPostedData () {
        this.detailsData = this.$store.dispatch('socket/clearPostedData')
      }
    }
  }
</script>
