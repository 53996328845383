<template>
  <highcharts
    :options="chartConfig"
    ref="highcharts"></highcharts>
</template>
<script>
  import moment from 'moment'

  export default {
    name: 'ChartRequestPerSecond',
    data () {
      let getCountPerSecond = (time) => {
        return this.$store.getters['socket/getRequestCountPerSecond'](time)
      }
      // Note look at L 37
      // let consoleLogData = 0
      return {
        chartConfig: {
          plotOptions: {
            spline: {
              marker: {
                enabled: false
              }
            }
          },
          chart: {
            backgroundColor: 'transparent',
            type: 'spline',
            height: 140,
            marginRight: 10,
            events: {
              load () {
                let series = this.series[0]
                clearInterval(window.chartUpdateInterval)
                window.chartUpdateInterval = setInterval(function () {
                  let x = (new Date()).valueOf()
                  let y = getCountPerSecond(x)
                  // Note for test chart uncommnet next line and look at console
                  // console.log(cachedTime)
                  // console.log(consoleLogData += y)
                  series.addPoint([x, y], true, true)
                }, 1000)
              }
            }
          },
          credits: {
            enabled: false
          },
          colors: ['#1a92cf', '#093247'],
          title: {
            text: 'Requests Per Second',
            verticalAlign: 'bottom',
            style: {
              'font-size': '.9em',
              'color': '#ccc',
              'height': '2em',
              'padding-top': '2em'
            }
          },
          xAxis: {
            type: 'datetime',
            tickPixelInterval: 100,
            labels: {
              formatter: function (x, y) {
                return new moment(this.value).format('HH:mm:ss')
              },
              style: {
                'color': '#093247'
              }
            }
          },
          yAxis: {
            enabled: false,
            title: {
              enabled: false,
              text: 'Value'
            },
            plotLines: [{
              value: 0,
              width: 1,
              color: '#808080'
            }]
          },
          legend: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          series: [{
            name: 'Requests',
            data: (function () {
              // generate an array of random data
              let data = []
              let time = (new Date()).getTime()
              let i
              for (i = -19; i <= 0; i++) {
                data.push({
                  x: time + i * 1000,
                  y: 0
                })
              }
              return data
            })()

          }]
        }
      }
    },
    beforeDestroy () {
      clearInterval(window.chartUpdateInterval)
    }
  }
</script>
