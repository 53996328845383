<template>
  <div class="row">
    <div class="request-details-no-result mt-5 m-auto col-12 col-lg-12 col-xl-12">
      <SampleRequest :url="endpointUrl"></SampleRequest>
    </div>
  </div>
</template>

<script>
  import Config from '@/config/config.js'
  import SampleRequest from '../components/SampleRequest'

  export default {
    name: 'AnimateNumbers',
    components: {
      SampleRequest
    },
    data () {
      return {
        globalConfig: Config
      }
    },
    computed: {
      endpointUrl () {
        return Config.Urls.EndPointBaseUrl + this.$route.query.id
      }
    }
  }
</script>
