<template>
  <!--Page header-->
  <nav class="page-header navbar navbar-expand-lg fixed-top pt-1 pb-1">
    <a
      class="navbar-brand"
      href="/">
      <img
        src="../../public/img/logo.svg"
        height="40"
        alt="image">
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav5"
      aria-controls="navbarNav5"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      id="navbarNav5"
      class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <!--<li class="nav-item active">-->
        <!--<a class="nav-link"-->
        <!--href="#">-->
        <!--Docs-->
        <!--<span class="sr-only">(current)</span>-->
        <!--</a>-->
        <!--</li>-->
      </ul>
      <div class="float-right">
        <div class="input-group input-group-sm">
          <label
            for="connectionUrlInput"
            class="endpoint-url-label">
            <span>http<span>&#8202;</span><small>(</small>s<small>)</small></span>
            <span class="mx-1">-</span>
            <span>mqtt<span>&#8202;</span><small>(</small>s<small>)</small></span>
          </label>
          <input
            id="connectionUrlInput"
            v-model="endpointUrl"
            type="text"
            readonly
            class="form-control"/>
          <div
            v-b-tooltip.hover
            class="input-group-append clipboard-btn"
            title="Copy"
            data-clipboard-target="#connectionUrlInput">
              <span class="input-group-text btn btn-sm btn-primary">
                <icon :name="copyIcon"></icon>
              </span>
          </div>
        </div>
      </div>
      <div class="float-right ml-3">
        <button
          v-b-tooltip.hover
          :class="{'btn-success': socket && socket.status === 'connected'}"
          class="btn-sm btn-numb"
          disabled
          title="Socket Status">
          <icon name="plug"></icon>
        </button>
      </div>
    </div>
  </nav>
</template>
<script>
  import Config from '@/config/config.js'
  import {mapState} from 'vuex'
  import ClipboardJS from 'clipboard'

  export default {
    name: 'PageHeader',
    data () {
      return {
        copyIcon: 'copy',
        showHappyClipboardTimeout: 2000,
        globalConfig: Config
      }
    },
    computed: {
      ...mapState('socket', ['overallData', 'endpointData', 'postedData', 'socket']),
      endpointUrl () {
        return Config.Urls.EndPurePointUrl + this.$route.query.id
      }
    },
    mounted () {
      this.initClipBoard()
    },
    methods: {
      initClipBoard () {
        // Handle clipboard functionality
        /* eslint-disable */
        this.clipboard = new ClipboardJS('.clipboard-btn')
        this.clipboard.on('success', () => {
          this.copyIcon = 'check'
          setTimeout(() => {
            this.copyIcon = 'copy'
          }, this.showHappyClipboardTimeout)
        })
        /* eslint-enable */
      }
    }
  }
</script>
